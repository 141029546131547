import { useForm } from "react-hook-form";

const ContactForm = () => {
    const { register, errors } = useForm({
        mode: "onBlur",
    });
    return (
        <form
            id="contact-form"
            action="https://getform.io/f/d713b25d-3a14-428c-8ed5-8046f9724c12"
            method="POST"
        >
            <div className="row">
                <div className="col-md-6">
                    <div className="single-form">
                        <input
                            type="text"
                            name="name"
                            placeholder="Tu nombre"
                            ref={register({ required: "El nombre es requierdo" })}
                        />
                        {errors.name && <p>{errors.name.message}</p>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="single-form">
                        <input
                            type="email"
                            name="email"
                            placeholder="tumail@dominio.com"
                            ref={register({
                                required: "Email es requerido",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Dirección de Email invalida",
                                },
                            })}
                        />
                        {errors.email && <p>{errors.email.message}</p>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="single-form">
                        <input
                            type="text"
                            name="subject"
                            placeholder="Asunto"
                            ref={register({
                                required: "El asunto es requerido",
                            })}
                        />
                        {errors.subject && <p>{errors.subject.message}</p>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="single-form">
                        <textarea
                            name="message"
                            placeholder="Escribí tu mensaje!"
                            ref={register({
                                required: "Por favor escribí algo",
                            })}
                        ></textarea>
                        {errors.message && <p>{errors.message.message}</p>}
                    </div>
                </div>
                <p className="form-message"></p>
                <div className="col-md-12">
                    <div className="form-btn">
                        <button type="submit">Enviar mensaje</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
