const HomeAbout = () => {
    return (
        <div className="section bg-dark overflow-hidden">
            <div className="about-section section-padding mt-n3 mb-n1">
                <div
                    className="about-images"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/about-images.png)`,
                    }}
                ></div>

                <div className="container">
                    <div
                        className="row justify-content-end"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-6">
                            <div className="about-content">
                                <p className="about-text">
                                Nacido en Buenos Aires en 1959, Adrián egresó de California Institute of the Arts con una licenciatura (BFA) en composición musical con énfasis en música electrónica. A mediados de los 90 egresó de San Francisco State University con una maestría (MA) en radio y televisión.
                                </p>
                                <p>
                                En Estados Unidos hizo música y post producción de sonido para documentales, animación, y diseño de sonido para obras teatrales (Satya a prayer for the enemy, La muerte y la doncella, etc.) 
                                </p>
                                <p>
                                A mediados de los 90 vuelve a Argentina donde se concentra en post producción de sonido, primero para comerciales y luego para televisión y cine. Desde fines del 2000 hasta el presente es encargado de la post de sonido en PolKa Producciones, co-dirigiendo el sonido de programas tales como Mujeres Asesinas, Soy Gitano, Son Amores, Durmiendo con mi Jefe, Padre Coraje, Para Vestir Santos, El Lobista, Amas de Casa Desesperadas, Locas de Amor, Tratame Bien, La Fragilidad de los cuerpos, Guapas, Violetta, Luna, etc. En cine realizó la dirección de sonido de películas tales como Dejala correr, El día que me amen, El hombre de al lado, Mariposa, El ciudadano ilustre, Mi obra maestra, etc.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
